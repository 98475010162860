import { useEffect, useState } from 'react';
import { AppState } from 'react-native';

export const useIsAppActive = () => {
    const [isAppActive, setIsAppActive] = useState(true);

    useEffect(() => {
        AppState.addEventListener('change', (nextAppState) => setIsAppActive(nextAppState === 'active'));
    }, []);

    return isAppActive;
};
